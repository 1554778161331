import React from 'react';
import SectionEight from "../components/new components/NewHomeComponents/SectionEight";
import SectionEleven from "../components/new components/NewHomeComponents/SectionEleven";
import SectionFive from "../components/new components/NewHomeComponents/SectionFive";
import SectionFour from "../components/new components/NewHomeComponents/SectionFour";
import SectionNine from "../components/new components/NewHomeComponents/SectionNine";
import SectionOne from "../components/new components/NewHomeComponents/SectionOne";
import SectionSeven from "../components/new components/NewHomeComponents/SectionSeven";
import SectionSix from "../components/new components/NewHomeComponents/SectionSix";
import SectionTen from "../components/new components/NewHomeComponents/SectionTen";
import SectionThree from "../components/new components/NewHomeComponents/SectionThree";
import SectionTwo from "../components/new components/NewHomeComponents/SectionTwo";

const NewHomePage = () => {
  return (
    <>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour /> 
      <SectionFive />
      <SectionSix />
      <SectionSeven />
      <SectionEight />
      <SectionNine />
      <SectionTen />
      <SectionEleven />
    </>
  );
};

export default NewHomePage;
