import { useState } from "react";
import React from 'react';


const BEPCalculator = () => {


    const [fixedCost, setFixedCost] = useState("");
    const [unit, setUnit] = useState("");
    const [variableCost, setVariableCost] = useState("");
    
    const [BEPUnit, setBEPUnit] = useState();
    const [BEPValue, setBEPValue] = useState();



    return (
        <div className="flex flex-col mt-4  h-[45em] lg:h-[40em] w-[90vw] lg:max-w-[50em] mx-auto pt-8">
            <div className="flex mx-auto gap-20 items-center justify-between  ">
                <label htmlFor="revenue" className="lg:w-[8em] w-[5em]" >Fixed Cost</label>
                <input type="text" className="w-[10em] border-[1.2px] rounded-lg border-[#EB2590] outline-[#EB2590]" name="revenue" value={fixedCost} 
                onChange={(e) => {setFixedCost(e.target.value);setBEPUnit(e.target.value / (unit - variableCost));
                setBEPValue((e.target.value / (unit - variableCost)) * unit);}} 
                />
            </div>
            <div className="flex mx-auto gap-20 items-center justify-between">
                <label htmlFor="expense" className="lg:w-[8em] w-[5em]" >Variable Cost</label>
                <input type="text"  className="w-[10em] border-[1.2px] rounded-lg border-[#EB2590] outline-[#EB2590]" name="expense" value={variableCost} 
                onChange={(e) => {setVariableCost(e.target.value); setBEPUnit(fixedCost / (unit - e.target.value));
                setBEPValue((fixedCost / (unit - e.target.value)) * unit);}} />
            </div>
            <div className="flex mx-auto gap-20 items-center justify-between">
                <label htmlFor="investment" className="lg:w-[8em] w-[5em]" >Selling Price/unit</label>
                <input type="text" className="w-[10em] border-[1.2px] rounded-lg border-[#EB2590] outline-[#EB2590]" name="investment" value={unit} 
                onChange={(e) => {setUnit(e.target.value);setBEPUnit(fixedCost / (e.target.value - variableCost));
                setBEPValue((fixedCost / (e.target.value - variableCost)) * e.target.value);
                }} />
            </div>


            <div className="flex mx-auto gap-20 items-center justify-between mt-5">
                <label htmlFor="net_profit" className="lg:w-[8em] w-[5em]" >BEP (Unit)</label>
                <div className="border-[2px] border-[#EB2590] rounded-lg w-[10em] p-1 px-2  h-[2em]">{(BEPUnit !== undefined && !isNaN(BEPUnit) && isFinite(BEPUnit) && BEPUnit > 0 ) && '#' + (Math.round(parseFloat(BEPUnit) * 100) / 100).toLocaleString()}</div>
            </div>
            <div className="flex mx-auto gap-20 items-center justify-between mt-3">
                <label htmlFor="net_profit" className="lg:w-[8em] w-[5em]" >BEP (Value)</label>
                <div className="border-[2px] border-[#EB2590] rounded-lg w-[10em] p-1 px-2 h-[2em] ">{(BEPValue !== undefined && !isNaN(BEPValue) && BEPValue > 0 ) && '#' +  (Math.round(parseFloat(BEPValue) * 100) / 100).toLocaleString()}</div>
            </div>    




            <div className="text-[12px] font-[800] mt-5 pl-3">
                <ul className="list-disc flex flex-col gap-5  justify-center">
                    <li>
                        Fixed Cost - Expenses that remain constant regardless of the level of production or sales, such as rent, salaries, insurance premiums, and depreciation.						
                    </li>
                    <li>
                        Variable Cost - Expenses that vary directly with the level of production or sales, such as raw materials, direct labor, and utilities.						
                    </li>
                    <li>
                        Selling Price/unit - The price at which a single unit of a product or service is sold to customers, determined based on factors such as production costs, market demand, and competition.			
                    </li>
                    <li>
                        BEP (Unit) - Break-Even Point (Unit) - The number of units of a product or service that must be sold to cover all fixed and variable costs, resulting in zero profit or loss.			
                    </li>
                    <li>
                        BEP (Value) - Break-Even Point (Value) - The total revenue needed to cover all fixed and variable costs, resulting in zero profit or loss. It is calculated by multiplying the break-even point (unit) by the selling price per unit.			
                    </li>
                   
                </ul>
            </div>
        </div>
    );
};
export default BEPCalculator;