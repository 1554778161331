import React from 'react';
import classes from "./LoadMoreBtn.module.css";

const LoadMoreBtn = (props) => {
    return (
        <div className={classes['container']} onClick={props.onClick}>
            See More
        </div>
    );
};

export default LoadMoreBtn;