import React, { useState } from "react";
import classes from "./NavBar.module.css";
import HeaderLink from "../UI/HeaderLink";



const NavBar = (props) => {

    const [openNav, setOpenNav] = useState(0);
    

    const companyLiClass = classes['header-nav__li'];
    const companySubMenuClass = (openNav === 1) ? classes['header-nav__submenu'] + " " + classes['display'] : classes['header-nav__submenu'];
    const businessLiClass = classes['header-nav__li'];
    const businessSubMenuClass = (openNav === 2) ? classes['header-nav__submenu'] + " " + classes['display'] : classes['header-nav__submenu'];
    const communityLiClass = classes['header-nav__li'];
    const communitySubMenuClass = (openNav === 3) ? classes['header-nav__submenu'] + " " + classes['display'] : classes['header-nav__submenu'];
    
    
    const clickHandler = () => {
        setOpenNav(0);
        props.onClick();
    };

    const navLinks = [
        {
            key: 1,
            menuText: "Company",
            subMenu: [
                        {key: 1, subMenuTitle: "Our Story", subMenuDetails: "Our journey so far at Shecluded", subMenuTo: "/about"},
                        {key: 2, subMenuTitle: "Careers", subMenuDetails: "Join us to make impact across Africa", subMenuTo: "/jobs"},
                        // {key: 3, subMenuTitle: "Leadership", subMenuDetails: "Meet the women that lead us", subMenuTo: "/our_story#founder"},
                    ],
            liClass: companyLiClass,
            subMenuClass: companySubMenuClass,
        },
        {
            key: 2,
            menuText: "Business",
            subMenu: [
                        {key: 1, subMenuTitle: "Loans", subMenuDetails: "See loan application criteria and appy for loans", subMenuTo: "/loan"},
                        {key: 2, subMenuTitle: "Shecluded Hub", subMenuDetails: "Learn more about Shecluded Hub", subMenuTo: "/shecluded_hub"},
                        // {key: 3, subMenuTitle: "Shecluded Hub", subMenuDetails: "Welcome to the Workspace built for you", subMenuTo: "/shecluded_hub"},
                        // {key: 3, subMenuTitle: "Loans", subMenuDetails: "Apply for loans and receive after approval", subMenuTo: "/about_loans"}
                    ],
            liClass: businessLiClass,
            subMenuClass: businessSubMenuClass,
        },
        {
            key: 3,
            menuText: "Community",
            subMenu: [
                        // {key: 1, subMenuTitle: "Shecluded Hub", subMenuDetails: "Welcome to the Workspace built for you", subMenuTo: "/shecluded_hub"},
                        {key: 2, subMenuTitle: "Entrepreneur Stories", subMenuDetails: "Learn from those who have been at the Hub", subMenuTo: "/testimonials"},
                        {key: 3, subMenuTitle: "Join a Community", subMenuDetails: "A group of Likeminded Business women", to: "https://t.me/+D0jH56wEIpsyMTdk"}
                    ],
            liClass: communityLiClass,
            subMenuClass: communitySubMenuClass,
        },
        {
            key: 4,
            menuText: "Blog",
            subMenu: [],
            liClass: classes['header-nav__li'],
            to: "https://blog.shecluded.com/",
        },
        // {
        //         key: 5,
        //         menuText: "Register",
        //         subMenu: [],
        //         liClass: classes['header-nav__li'] + " " + classes['header-nav_reg'],
        //         inpage: true,
        //         to: "/register"
        //     },
       
    ];

    return (
       <HeaderLink setOpenNav = {setOpenNav} navLinks = {navLinks} onClick={clickHandler} />
    );
};


export default NavBar;