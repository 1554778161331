import React from 'react';


const SectionSeven = () => {
    return (
        <div className="flex flex-col lg:flex-row p-0 mx-auto max-w-[100vw] lg:mt-20 mb-40 lg:max-w-[75em] h-[646.26px]">

            <div className="flex-1 bg-[#FF7733] lg:bg-[#F598D8] flex flex-col items-center justify-center py-4 lg:gap-3 lg:p-10 ">
                <img src="/images/sectionseven_1.png" alt="sectionsevenone" className="mx-auto rounded-t-full h-[387.58px] lg:h-auto" />
                <p className="text-center font-playfair font-[400] text-[30px] leading-[39.99px] text-white lg:text-black ">The Shecluded <span className="italic text-black lg:text-custom-primary">Hub Space</span></p>
            </div>
            <div className="flex-1 relative ">
                <img src="/images/sectionseven_2.png" alt="sectionseventwo" className="m-0" />
               <div className="flex absolute w-full h-[85px] lg:h-[200px] mt-[-170px] lg:mt-[-220px]">
                    <div className="flex-[.5] m-0 w-full"></div>
                    <div className="flex-1 m-0 bg-custom-primary w-full "></div>
               </div>
                <div className="absolute m-0 bg-custom-primary w-full h-[85px] lg:h-[150px] bottom-0 flex justify-cemter items-center px-10 ">
                    <p className="font-lato font-[500] text-[22px] lg:text-[33px] leading-[26.4px] lg:leading-[39.6px] text-center text-white my-auto ">A Space where Women <span className="leading-[29.33px] lg:leading-[43.99px] italic font-playfair">Collaborate, Learn</span> & Create</p>
                </div>
            </div>

        </div>
    );
};
export default SectionSeven;