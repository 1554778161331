import { NavLink } from "react-router-dom";
import React from 'react';


const SectionTen = () => {
    return (
        <div className="w-full lg:px-40 h-[804.74px] bg-[#FFE9F8] flex justify-center items-center mt-[150px] lg:mt-0 mb-20 lg:mb-3">

            <div className="flex flex-col gap-10 lg:gap-0 lg:flex-row p-0 mx-auto max-w-[90vw] lg:max-w-[75em]  justify-center items-center">
                <div className="lg:flex-[.5] relative">
                    <div className="relative z-20 lg:mr-[-80px]">
                        <div className="bg-white p-4 lg:p-2">
                            <p className="font-playfair font-[400] text-[32px] lg:text-[50px] leading-[42.66px] lg:leading-[66.65px] text-center ">Want to meet <span className="text-custom-primary">Women like you?</span></p>
                        </div>
                        <NavLink
                            to="/our_story"
                            className="mx-auto flex w-[143px] lg:w-[166px] h-[37px] lg:h-[39px] lg:border-2 rounded-full gap-3 lg:gap-0 justify-center !no-underline mt-[-20px]
                                        items-center lg:border-custom-primary px-[30px] py-[7px] lg:px-0 lg:py-0 text-[12px] lg:text-[14.4px] font-[500] leading-[20.4px] lg:leading-[14.4px] text-custom-primary text-white bg-custom-primary "
                            >
                            Click to Join
                        </NavLink>
                   </div>
                </div>
                <div className="flex-1 flex flex-col items-center gap-1" >
                    <div className="flex items-center justify-center ">
                        <img src="/images/sectionten_1.png" alt="" className="w-[40vw] lg:h-[257.35px] lg:w-[343.69px] " />
                        <img src="/images/sectionten_2.png" alt="" className= "w-[40vw] lg:h-[257.35px] lg:w-[343.69px] " />
                    </div>
                    <img src="/images/sectionten_3.png" alt="" className="w-[85vw] lg:w-[730.45px] lg:h-[305.6px] " />
                </div>

            </div>
           
        </div>
    );
};
export default SectionTen;