import React from 'react';


const SectionOne = () => {
    return (
        <div className="flex flex-col lg:flex-row bg-white max-w-[90vw] text-center  pt-10 lg:max-w-[75em] mx-auto justify-between gap-5 items-center">
            <div className="flex-[1] lg:!text-left">
                <p className="font-playfair font-[600] text-[39px] lg:text-[56px] leading-[51.99px] lg:leading-[74.65px] ">Powering <span className="italic font-[500]  text-custom-primary">Finance for Women</span> owned Businesses in Africa</p>
                <p className="font-lato font-[300] text-[24px] leading-[28.8px] ">Shecluded is a funding company that provides loans to women in business</p>
            </div>
            <div className="flex-[.9] pb-[100px] ">
                <img src="/images/sectionone.png" alt="sectionone"  />
            </div>
        </div>
    );
};
export default SectionOne;