import React from "react";
import FAQ from "./FAQ";

const ShecludedPensionScheme = () => {
   

    const accordionData = [
        {
            'key': 1, 
            'title' : 'What other services are available on Shecluded?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "We develop strategic programs with public & private organizations to deliver bespoke training, incubator & accelerator for women in business, Shecluded Hub,(The hub is more than a physical space), savings wealth management,  health insurance and Pension"
                    ]
                },
                
                
            ]
        },
        {
            'key': 2, 
            'title' : 'How do I join the Shecluded pension scheme?', 
            'body' : [
                {
                    title: "The Shecluded pension scheme was created to help more women get access to pension plans and also improve the retirement saving culture among African women. Our pension plans are free, to join you would need the following;",
                    texts: [
                       "A completed pension form",
                       "An NIN Card or Slip ('No NIN, No PIN')",
                       "Means of ID (Valid Drivers License/Voters' Card/International Passport/Company ID card)",
                       "Proof of Address",
                       "A passport photograph on a clear background"

                    ]
                },
                
                
            ]
        },
        {
            'key': 3, 
            'title' : 'How do I join the Shecluded health insurance scheme?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Click the link below to review our new HMO plans and select the one that best fits your needs: https://www.subscribepage.com/shecludedhmo"
                    ]
                },
                
                
            ]
        },
        {
            'key': 4, 
            'title' : 'How do I access the business advisory services?', 
            'body' : [
                {
                    title: "The business advisory service is a paid service where women get a starting guide for their business, step by step action plan to goal, new product development, and pivot and action plan. To access the service, you would pay a stipulated fee to book a consultation with an advisor. Please contact us at:",
                    texts: [
                        "Phone +2347048000892",
                        "Email: hello@shecluded.com",
                        "Website:  https://shecluded.com",
                        "Office address: TSC Building Plot 8, Rock Drive Off C & I Leasing Drive, Lekki Phase, Lagos State Nigeria"
                    ]
                },
                
                
            ]
        },
      

    ];

    return (
        <FAQ title = "Shecluded Pension Scheme" contStyle= {{padding: window.innerWidth <= 1024 && "0 .5em", }} accordionData = {accordionData} black plusMinus />
    );
};

export default ShecludedPensionScheme;