
import React from 'react';




const PricingCalculator = () => {

    const [totalCost, setTotalCost] = useState("");
    const [unit, setUnit] = useState("");
    const [profitMargin, setProfitMargin] = useState("");
    
    const [profit, setProfit] = useState();
    const [totalPrice, setTotalPrice] = useState();
    const [unitPrice, setUnitPrice] = useState();


    return (
        <div className="flex flex-col mt-4 h-[55em] lg:h-[45em] w-[90vw] lg:max-w-[50em] mx-auto pt-8">
            <div className="flex mx-auto gap-20 items-center justify-between  ">
                <label htmlFor="revenue" className="lg:w-[8em] w-[5em]" >Total Cost</label>
                <input type="text" className="w-[10em] border-[1.2px] rounded-lg border-[#EB2590] outline-[#EB2590]" name="revenue" value={totalCost} 
                onChange={(e) => {setTotalCost(e.target.value);setProfit(e.target.value * (profitMargin / 100)); setTotalPrice(parseFloat(e.target.value) + parseFloat(e.target.value * (profitMargin / 100)));
                setUnitPrice((parseFloat(e.target.value) + parseFloat(e.target.value * (profitMargin / 100))) / unit);}} 
                />
            </div>
            <div className="flex mx-auto gap-20 items-center justify-between">
                <label htmlFor="expense" className="lg:w-[8em] w-[5em]" >Unit</label>
                <input type="text"  className="w-[10em] border-[1.2px] rounded-lg border-[#EB2590] outline-[#EB2590]" name="expense" value={unit} onChange={(e) => {setUnit(e.target.value); setUnitPrice(totalPrice / e.target.value)}} />
            </div>
            <div className="flex mx-auto gap-20 items-center justify-between">
                <label htmlFor="investment" className="lg:w-[8em] w-[5em]" >Desired Profit Margin (%)</label>
                <input type="text" className="w-[10em] border-[1.2px] rounded-lg border-[#EB2590] outline-[#EB2590]" name="investment" value={profitMargin} 
                onChange={(e) => {setProfitMargin(e.target.value);setProfit(totalCost * (e.target.value / 100)); setTotalPrice(parseFloat(totalCost) + parseFloat(totalCost * (e.target.value / 100)));
                    setUnitPrice((parseFloat(totalCost) + parseFloat(totalCost * (e.target.value / 100))) / unit);
                }} />
            </div>


            <div className="flex mx-auto gap-20 items-center justify-between mt-5">
                <label htmlFor="net_profit" className="lg:w-[8em] w-[5em]" >Profit</label>
                <div className="border-[2px] border-[#EB2590] rounded-lg w-[10em] p-1 px-2  h-[2em]">{(profit !== undefined && !isNaN(profit) && profit > 0 ) && '#' + (Math.round(parseFloat(profit) * 100) / 100).toLocaleString()}</div>
            </div>
            <div className="flex mx-auto gap-20 items-center justify-between mt-3">
                <label htmlFor="net_profit" className="lg:w-[8em] w-[5em]" >Total Selling Price</label>
                <div className="border-[2px] border-[#EB2590] rounded-lg w-[10em] p-1 px-2 h-[2em] ">{(totalPrice !== undefined && !isNaN(totalPrice) && totalPrice > 0 ) && '#' +  (Math.round(parseFloat(totalPrice) * 100) / 100).toLocaleString()}</div>
            </div>    
            <div className="flex mx-auto gap-20 items-center justify-between mt-3">
                <label htmlFor="roi" className="lg:w-[8em] w-[5em]" >Selling Price/unit</label>
                <div className="border-[2px] border-[#EB2590] rounded-lg w-[10em] p-1 px-2  h-[2em]">{(unitPrice !== undefined && !isNaN(unitPrice) && unitPrice > 0) && '#' + (Math.round(parseFloat(unitPrice) * 100) / 100).toLocaleString()}</div>
            </div>


            <div className="text-[12px] font-[800] mt-5 pl-3">
                <ul className="list-disc flex flex-col gap-5  justify-center">
                    <li>
                        Total Cost - The total expenditure incurred by the business to produce or acquire goods or services, including both fixed and variable costs.						
                    </li>
                    <li>
                        Unit - A single item or quantity of a product or service, typically used for pricing and inventory management purposes.					
                    </li>
                    <li>
                        Desired Profit Margin - The percentage or amount of profit that the business aims to achieve on each sale, calculated as a proportion of the total cost or selling price.			
                    </li>
                    <li>
                        Profit - The financial gain or positive difference between revenue and expenses, representing the amount earned by the business after deducting all costs.			
                    </li>
                    <li>
                        Total Selling Price - The aggregate amount of revenue generated from the sale of goods or services, calculated by multiplying the selling price per unit by the total quantity sold.
                    </li>
                    <li>
                        Selling Price/unit - The price at which a single unit of a product or service is sold to customers, determined based on factors such as production costs, market demand, and competition.
                    </li>
                  
                </ul>
            </div>
        </div>
    );
};
export default PricingCalculator;