import React from 'react';
import HomeWhatsNewSection from "../HomeComponents/HomeWhatsNewSection";



const SectionEleven = () => {
    return (
        <HomeWhatsNewSection />
    );
};
export default SectionEleven;