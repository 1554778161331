import React from "react";
import FAQ from "./FAQ";

const ShecludedProgramFAQSection = () => {
   

    const accordionData = [
        {
            'key': 1, 
            'title' : 'What\'s in the Incubator program?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "This program is for women who want to begin or have recently started a business. It includes a 4-week training that covers important parts of business development, such as handling finances, marketing, and understanding business regulations (like registering your business). We bring in experts from various fields to teach and guide each participant."
                    ]
                },
                
                
            ]
        },
        {
            'key': 2, 
            'title' : 'What does the Accelerator program mean?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "It's a program made for women already in business who want to expand. The 4-week training zeroes in on important parts of growing a business to help create a brand that goes global."
                    ]
                },
                
                
            ]
        },
        {
            'key': 3, 
            'title' : 'What is the duration of the Incubator program?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Some programs last for a certain period, and on average, it's about 4 weeks."
                    ]
                },
                
                
            ]
        },
        {
            'key': 4, 
            'title' : 'Will I receive a certificate upon completing the program?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "If you attend all sessions, fill out forms, and sign attendance sheets successfully, you will receive a certificate."
                    ]
                },
                
                
            ]
        },
        {
            'key': 5, 
            'title' : 'Can I join both the incubator program and accelerator at the same time?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Yes, however, those events happen at different times."
                    ]
                },
                
                
            ]
        },
        {
            'key': 6, 
            'title' : 'What benefits do these programs provide?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "We provide personalized mentorship, funding, lower interest rates for loans, certifications, and access to a network of women entrepreneurs."
                    ]
                },
                
                
            ]
        },
        {
            'key': 7, 
            'title' : 'Do I need a business before joining?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "You can customize your workspace to some extent. Please consult with our community manager or staff for guidelines on personalization."
                    ]
                },
                
                
            ]
        },
        {
            'key': 8, 
            'title' : 'Can I secure a financial grant or loan upon completing the program?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Of course, you can apply for the program even while it's ongoing."
                    ]
                },
                
                
            ]
        },
        {
            'key': 9, 
            'title' : 'Are the programs in person or online?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Many of our programs are a mix of online and in-person activities. Check each program to see if you can get a grant or loan when you finish"
                    ]
                },
                
                
            ]
        },
        {
            'key': 10, 
            'title' : 'Can I get money as a grant or loan after finishing the program successfully?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Yes, you can also apply while you're in the program."
                    ]
                },
                
                
            ]
        },
        {
            'key': 11, 
            'title' : 'How can I contact your customer support for more help with this program?', 
            'body' : [
                {
                    title: "",
                    texts: [
                        "Phone +2347048000892",
                        "Email: hello@shecluded.com",
                        "Website:  https://shecluded.com",
                        "Office address: TSC Building Plot 8, Rock Drive Off C & I Leasing Drive, Lekki Phase, Lagos State Nigeria"
                    ]
                },
                
                
            ]
        },

    ];

    return (
        <FAQ title = "Shecluded Program" contStyle= {{padding: window.innerWidth <= 1024 && "0 .5em", }} accordionData = {accordionData} black plusMinus />
    );
};

export default ShecludedProgramFAQSection;