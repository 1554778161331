import { useState } from "react";
import React from 'react';


const LoanCalculator = () => {

    // const [items, setItems] = useState({
    //     revenue:0,
    //     expense:0,
    //     investment:0,
    //     interest:0
    // });
    const [revenue, setRevenue] = useState("");
    const [expense, setExpense] = useState("");
    const [investment, setInvestment] = useState("");
    const [interest, setInterest] = useState('');
    const [netProfit, setNetProfit] = useState();
    const [roi, setRoi] = useState();



    return(
        <div className="flex flex-col mt-4 h-[50em] lg:h-[45em] w-[90vw] lg:max-w-[60em] mx-auto">
            <p className="ml-2 lg:ml-10 font-[700] lg:w-[23em] lg:mt-2 ">Net Profit Section</p>
            <div className="flex mx-auto gap-20 items-center justify-between  ">
                <label htmlFor="revenue" className="lg:w-[8em] w-[5em]" >Revenue</label>
                <input type="text" className="w-[10em] border-[1.2px] rounded-lg border-[#EB2590] outline-[#EB2590]" name="revenue" value={revenue} onChange={(e) => {setRevenue(e.target.value);setNetProfit(e.target.value - expense);}} />
            </div>
            <div className="flex mx-auto gap-20 items-center justify-between">
                <label htmlFor="expense" className="lg:w-[8em] w-[5em]" >Expense</label>
                <input type="text"  className="w-[10em] border-[1.2px] rounded-lg border-[#EB2590] outline-[#EB2590]" name="expense" value={expense} onChange={(e) => {setExpense(e.target.value);setNetProfit(revenue - e.target.value);}} />
            </div>
            <div className="flex mx-auto gap-20 items-center justify-center mt-3">
                <label htmlFor="net_profit" className="lg:w-[8em] w-[5em]" >Net Profit</label>
                <div className="border-[2px] border-[#EB2590] rounded-lg w-[10em] p-1 px-2  h-[1.8em]">{(netProfit !== undefined && !isNaN(netProfit) && netProfit > 0 ) && '#' + Math.round(parseFloat(netProfit) * 100) / 100}</div>
            </div>

            <p className="ml-2 leading-none mt-4 font-[700] lg:w-[23em] lg:mt-2 lg:mx-auto">ROI Section</p>
            <div className="flex mx-auto gap-20 items-center justify-between mt-1">
                <label htmlFor="net_profit" className="lg:w-[8em] w-[5em]" >Net Profit</label>
                <div className="border-[1.2px] border-[#EB2590] rounded-lg w-[10em] p-1 px-2 h-[1.8em] ">{(netProfit !== undefined && !isNaN(netProfit) && netProfit > 0 ) && '#' + netProfit}</div>
            </div>
            <div className="flex mx-auto gap-20 items-center justify-between">
                <label htmlFor="investment" className="lg:w-[8em] w-[5em]" >Investment(Bus. Loan)</label>
                <input type="text" className="w-[10em] border-[1.2px] rounded-lg border-[#EB2590] outline-[#EB2590]" name="investment" value={investment} onChange={(e) => {setInvestment(e.target.value);setRoi(netProfit/(parseFloat(e.target.value) + parseFloat(interest)));}} />
            </div>
            <div className="flex mx-auto gap-20 items-center justify-between">
                <label htmlFor="interest" className="lg:w-[8em] w-[5em]" >Interest</label>
                <input type="text" className="w-[10em] border-[1.2px] rounded-lg border-[#EB2590] outline-[#EB2590]" name="interest" value={interest} onChange={(e) => {setInterest(e.target.value);setRoi(netProfit/(parseFloat(investment) + parseFloat(e.target.value)));}} />
            </div>
            
            <div className="flex mx-auto gap-20 items-center justify-between mt-3">
                <label htmlFor="roi" className="lg:w-[8em] w-[5em]" >ROI</label>
                <div className="border-[2px] border-[#EB2590] rounded-lg w-[10em] p-1 px-2  h-[1.8em]">{(roi !== undefined && !isNaN(roi) && netProfit > 0) && Math.ceil(roi * 100) + '%'}</div>
            </div>

            <div className="text-[12px] font-[800] mt-5 pl-3">
                <ul className="list-disc flex flex-col gap-5  justify-center">
                    <li>
                        Revenue -  Total income generated by the business from its primary activities, such as sales of goods or services.			
                    </li>
                    <li>
                        Expense - Costs incurred by the business in the process of generating revenue, including operating expenses, salaries, utilities, and other overhead costs.			
                    </li>
                    <li>
                        Netprofit -  The difference between total revenue and total expenses, indicating the profitability of the business after all costs have been deducted.			
                    </li>
                    <li>
                        Investment(Bus. Loan) -  Funds borrowed by the business from external sources, typically for the purpose of financing operations, expansion, or investment in assets.			
                    </li>
                    <li>
                        Interest - The cost of borrowing money, typically expressed as a percentage of the loan amount, paid by the borrower to the lender over a specified period.			
                    </li>
                    <li>
                        ROI -  A measure of the profitability of an investment, calculated by dividing the net			
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default LoanCalculator;