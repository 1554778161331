import React from "react";
import FAQ from "./FAQ";

const ShecludedWealthManagement = () => {
   

    const accordionData = [
        {
            'key': 1, 
            'title' : 'What is Wealth Management?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Wealth Management is an advisory service that addresses the wants and needs of individuals by providing them with the appropriate financial services and products tailored to the individual’s goals. Wealth Management cuts across financial planning, investment advisory, retirement planning, wealth transfer etc."
                    ]
                },
                
                
            ]
        },
        {
            'key': 2, 
            'title' : 'Why is Wealth Management important?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Wealth Management is important because this service encompasses all aspects of an individual’s financial life. Wealth transcends beyond physical cash and the wealth management service assists you to put structures in place to get you to the financial future you want and deserve."
                    ]
                },
                
                
            ]
        },
        {
            'key': 3, 
            'title' : 'What will the Wealth Manager do for me?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "The Wealth Manager will consult with you to determine your specific circumstances, needs and goals, and provide you with a customized financial plan that helps you achieve those goals."
                    ]
                },
                
                
            ]
        },
        {
            'key': 4, 
            'title' : 'Can I lose my money investing?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "It is important to understand that investment instruments carry varying levels of risk and potential reward. Your risk profile and risk appetite will be determined during consultations before advising you on viable investment options."
                    ]
                },
                
                
            ]
        },
        {
            'key': 5, 
            'title' : 'Do I need to have a specific amount before accessing the Wealth Management service?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "No, you do not. The service is open to everyone however, we will advise you of how much you should be saving or investing based on your goals."
                    ]
                },
                
                
            ]
        },
        {
            'key': 6, 
            'title' : 'What is the wealth management and financial advisory service about and how do I have access to it?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "The wealth management and financial advisory service was designed to help women make their money work for them by offering scalable business, accounting, money management and investment solutions designed to meet specific needs of our clients. Our advisors work closely to learn more about your life and help develop and implement investment plans that support your financial goals. This is a paid service led by wealth managers and financial advisors who understand women and their money.  To access the service, kindly click here to book an appointment today."
                    ]
                },
                
                
            ]
        },
        {
            'key': 7, 
            'title' : 'What are your fees for the wealth management advisory service?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "At Shecluded, you will have the opportunity to speak with an advisor, and not a salesperson. Our wealth management program encompasses investment management and ongoing advisory support. Fees for this service are charged at $100."
                    ]
                },
                
                
            ]
        },
       

    ];

    return (
        <FAQ title = "Shecluded Wealth Management" contStyle= {{padding: window.innerWidth <= 1024 && "0 .5em", }} accordionData = {accordionData} black plusMinus />
    );
};

export default ShecludedWealthManagement;