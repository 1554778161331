import React, { useState } from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const SectionEight = () => {
  const [activeSection, setActiveSection] = useState(0); // 0 for the first section, 1 for the second
  const [touchStart, setTouchStart] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!touchStart) {
      return;
    }

    const touchEnd = e.touches[0].clientX;
    const touchDifference = touchStart - touchEnd;

    if (touchDifference > 50) { // Swipe left
      setActiveSection(1);
    } else if (touchDifference < -50) { // Swipe right
      setActiveSection(0);
    }
  };

  return (
    <div
      className="flex overflow-hidden p-0 mx-auto max-w-[95vw] mt-[360px] lg:mt-[100px] mb-[80px] lg:mb-20 lg:max-w-[75em] lg:h-[646.26px]  "
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <div className={`flex transition-transform w-[75em] duration-500 ease-in-out transform ${activeSection === 0 ? 'translate-x-0' : '-translate-x-1/2 lg:-translate-x-0'}`}>
        
      <div className="flex-1 mr-3 max-w-[95vw] lg:max-w-auto">
        <div className="flex justify-between lg:justify-center items-center">
          <p className="font-playfair font-[400] text-[38px] leading-[50.65px] text-center ">
            Events
          </p>
          <div className="flex gap-1 items-center lg:hidden">
            <div className="w-3 h-3 rounded-full bg-[#F598D8]"></div>
            <div className="w-3 h-3 rounded-full bg-[#DBDBDB]"></div>
          </div>
        </div>
        <p className="font playfair font-[500] text-[22px] leading-[29.33px] text-center ">
          Hold your{" "}
          <span className="italic text-custom-primary">events, workshops</span>{" "}
          and seminars here
        </p>
        <div className="relative mt-10 lg:mt-[100px] mb-1 lg:mb-[90px]">
          <div className="bg-[#FFC95C] w-[93vw]  lg:max-w-auto lg:w-[480.22px] h-[181px] lg:h-[252px] mx-auto"></div>
          <div className=" bg-[#F598D8] opacity-70 lg:opacity-100 rounded-full h-[90.5px] w-[90.5px] lg:h-[243.33px] lg:w-[243.33px] absolute z-[100000] lg:z-[1000] mt-[-240px] lg:mt-[-320px] ml-calc lg:ml-[58px] "></div>
          <img
            src="/images/sectioneight_1.png"
            alt="sectioneightone"
            className="absolute w-[85vw] h-[181px] lg:w-auto lg:h-auto mt-[-181px] lg:mt-[-300px] z-[10000] ml-calc-img lg:ml-[90px]"
          />
        </div>
        <NavLink
          to="/"
          className="lg:mx-auto flex w-[166px] h-[39px] lg:border-2 rounded-full gap-3 lg:gap-0 lg:justify-center !no-underline lg:mt-20
                    items-center lg:border-custom-primary text-[17px] lg:text-[12px] font-[500] leading-[20.4px] lg:leading-[14.4px] text-custom-primary lg:text-white lg:bg-custom-primary "
        >
          Contact Sales <FaAngleRight className="block lg:hidden" />
        </NavLink>
      </div>
      <div className="flex-1 max-w-[95vw] lg:max-w-auto">
        <div className="flex justify-between lg:justify-center items-center">
          <p className="font-playfair font-[400] text-[38px] leading-[50.65px] text-center ">
            Upskill
          </p>
          <div className="flex gap-1 items-center lg:hidden">
            <div className="w-3 h-3 rounded-full bg-[#DBDBDB]"></div>
            <div className="w-3 h-3 rounded-full bg-[#F598D8]"></div>
          </div>
        </div>
        <p className="font playfair font-[500] text-[22px] leading-[29.33px] text-center ">
          Register for a{" "}
          <span className="italic text-custom-primary">business training</span>{" "}
          and come into the space
        </p>
        <div className="relative mt-10 lg:mt-[100px] mb-1 lg:mb-[90px]">
          <div className="bg-[#FFC95C] w-[93vw]  lg:max-w-auto lg:w-[480.22px] h-[181px] lg:h-[252px] mx-auto"></div>
          <div className="bg-[#EB2590] opacity-70 lg:opacity-100 rounded-full h-[90.5px] w-[90.5px] lg:h-[243.33px] lg:w-[243.33px] absolute z-[100000] lg:z-[1000] mt-[-240px] lg:mt-[-320px] ml-calc lg:ml-[58px]"></div>
          <img
            src="/images/sectioneight_2.png"
            alt="sectioneightone"
            className="absolute w-[85vw] h-[181px] lg:w-auto lg:h-auto mt-[-181px] lg:mt-[-300px] z-[10000] ml-calc-img lg:ml-[90px]"
          />
        </div>
        <NavLink
          to="/"
          className="lg:mx-auto flex w-[166px] h-[39px] lg:border-2 rounded-full gap-3 lg:gap-0 justify-center !no-underline lg:mt-20
          items-center lg:border-custom-primary text-[17px] lg:text-[12px] font-[500] leading-[20.4px] lg:leading-[14.4px] text-custom-primary lg:text-white lg:bg-custom-primary "
        >
          Book a Space <FaAngleRight className="block lg:hidden" />
        </NavLink>
      </div>
    </div>
    </div>
  );
};
export default SectionEight;
