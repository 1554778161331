import React from "react";
import FAQ from "./FAQ";

const AboutShecludedFAQSection = () => {
   

    const accordionData = [
        {
            'key': 1, 
            'title' : 'What is Shecluded?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "Shecluded is a digital financial inclusion company that is focused on providing women with access to credit, financial education, business advisory, wealth management and pension in order to help them achieve sustainable growth in their personal life, career and business."
                    ]
                },
                
                
            ]
        },
        {
            'key': 2, 
            'title' : 'How do I join the Shecluded Community?', 
            'body' : [
                {
                    title: "",
                    texts: [
                       "To join our community simply click: https://bit.ly/shecludedwbkZ"

                    ]
                },
                
                
            ]
        },

      

    ];

    return (
        <FAQ title = "About Shecluded" contStyle= {{padding: window.innerWidth <= 1024 && "0 .5em", }} accordionData = {accordionData} black plusMinus />
    );
};

export default AboutShecludedFAQSection;